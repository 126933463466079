@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Literata:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto+Mono:ital,wght@0,100;0,200;0,300;0,400;1,100;1,200;1,300;1,400&family=Roboto+Serif:ital,opsz,wght@0,8..144,100;0,8..144,200;0,8..144,300;0,8..144,400;1,8..144,100;1,8..144,200;1,8..144,300;1,8..144,400&display=swap");
/*      *      *      *     D E F A U L T S     *      *      *      */
html,
body {
  margin: 0;
  padding: 0;
  border: 0;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  text-size-adjust: none;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}

html {
  -moz-text-size-adjust: none;
  text-size-adjust: none;
  -webkit-text-size-adjust: none;
}
html :focus:not(:focus-visible) {
  outline: none;
}

body {
  font-size: 16px;
  font-family: "Literata", serif;
  color: #556870;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  scroll-behavior: smooth;
}

*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  text-decoration: none;
  width: 100%;
  max-width: 100%;
}

a {
  cursor: pointer;
  text-decoration: none;
}

::-webkit-scrollbar {
  background-color: #F8BC4D;
  width: 0.5em;
}

::-webkit-scrollbar-thumb {
  background-color: #005B72;
  width: 0.5em;
}

::-webkit-scrollbar-track {
  background-color: #F8BC4D;
  width: 0.5em;
}

@font-face {
  font-family: "NavLit";
  src: url("../assets/Literata-VariableFont_opsz,wght.ttf") format("truetype");
  font-variation-settings: "opsz" 300;
  font-style: normal;
  font-weight: 300;
  font-stretch: semi-expanded;
}
@font-face {
  font-family: "Roboto Mono";
  src: url("../assets/RobotoMono-VariableFont_wght.ttf") format("truetype");
  font-variation-settings: "opsz" 120;
  font-style: normal;
  font-stretch: ultra-expanded;
}
@font-face {
  font-family: "ErrF";
  src: url("../assets/Lato-Light.ttf") format("truetype");
  font-variation-settings: "opsz" 50;
  font-stretch: extra-expanded;
}
@font-face {
  font-family: "H4Lat";
  src: url("../assets/Lato-Light.ttf") format("truetype");
  font-weight: 100;
  font-variation-settings: "opsz" 120;
  font-style: normal;
  font-stretch: ultra-expanded;
}
@font-face {
  font-family: "SL";
  src: url("../assets/MahoganyLane-Regular.ttf") format("truetype");
  font-variation-settings: "opsz" 120;
  font-style: normal;
  font-stretch: extra-expanded;
}
@font-face {
  font-family: "BKN";
  src: url("../assets/MahoganyLane-Regular.ttf") format("truetype");
  font-variation-settings: "opsz" 120;
  font-style: normal;
  font-stretch: extra-expanded;
}
@font-face {
  font-family: "H2Lat";
  src: url("../assets/Lato-Thin.ttf") format("truetype");
  font-variation-settings: "opsz" 120;
  font-style: normal;
  font-stretch: extra-expanded;
}
@font-face {
  font-family: "H3Lat";
  src: url("../assets/Lato-Thin.ttf") format("truetype");
  font-variation-settings: "opsz" 120;
  font-style: normal;
  font-stretch: ultra-expanded;
}
@font-face {
  font-family: "Lat";
  src: url("../assets/Lato-Regular.ttf") format("truetype");
  font-variation-settings: "opsz" 120;
  font-style: normal;
  font-stretch: ultra-expanded;
}
@font-face {
  font-family: "MM";
  src: url("../assets/RobotoMono-VariableFont_wght.ttf") format("truetype");
  font-variation-settings: "opsz" 120;
  font-style: normal;
  font-weight: 400;
  font-stretch: semi-condensed;
}
@font-face {
  font-family: "SM";
  src: url("../assets/Literata-VariableFont_opsz,wght.ttf") format("truetype");
  font-weight: 350;
  font-style: italic;
  font-variation-settings: "opsz" 250;
  font-style: normal;
  font-stretch: ultra-expanded;
}
@font-face {
  font-family: "Font-TSF";
  font-weight: 100;
  src: url("../assets/fontspring-demo-theseasons-reg-webfont.woff2") format("woff2"), url("../assets/fontspring-demo-theseasons-reg-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Font-TSF-Light";
  font-weight: 100;
  src: url("../assets/fontspring-demo-theseasons-lt-webfont.woff2") format("woff2"), url("../assets/fontspring-demo-theseasons-lt-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
/*      *      *      *     S U P P O R T S     *      *      *      */
@media (prefers-reduced-motion: no-preference) {
  html {
    scroll-behavior: smooth;
  }
}
@media screen and (prefers-reduced-motion: reduce) {
  * {
    animation: 0.001ms !important;
    -o-animation: 0.001ms !important;
    -moz-animation: 0.001ms !important;
    -webkit-animation: 0.001ms !important;
  }
}
.gridset {
  --min: 16rem;
}

#loader {
  display: block;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 3px solid #ffc259;
  border-top: 5px solid #003e74;
  animation: loader ease 1000ms infinite;
}

/*      *      *      *     C O N T A I N E R S     *      *      *      */
.outer {
  display: flex;
  min-width: 100%;
  max-width: 100%;
  height: 100vh;
  font-family: "Roboto Serif", "Literata", serif;
  font-weight: 100;
  text-align: left;
  font-size: 16px;
  font-optical-sizing: auto;
  background-color: #fff;
  padding: 0;
  border: 0;
  margin-left: auto;
  margin-right: auto;
  cursor: auto;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  position: relative;
}

.container {
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-rows: 10% auto 3.5%;
  grid-template-columns: 1fr;
  grid-template-areas: "hd" "mc" "ft";
  gap: 0.1rem;
  margin: 0 auto;
  align-items: center;
  justify-content: space-between;
}

header {
  grid-area: hd;
  display: grid;
  width: 100%;
  max-width: 100%;
  min-height: 100%;
  grid-template-columns: 0.1fr 0.88fr;
  grid-template-rows: 1fr;
  grid-template-areas: "logo nav";
  gap: 0.2rem;
  position: relative;
  padding: 0;
  margin: 0 auto;
  align-items: center;
  justify-content: space-between;
  z-index: 99;
}

main {
  grid-area: mc;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  overflow-y: auto;
  scroll-behavior: smooth;
  position: relative;
}

footer {
  grid-area: ft;
  padding: 0em 0.2rem;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.ft {
  display: flex;
  width: 100%;
  height: 100%;
  color: #000000;
  position: relative;
  z-index: 5;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  font-family: "Literata", "Roboto Serif", serif;
  font-family: "Lato", sans-serif;
  text-transform: uppercase;
  font-weight: 300;
  font-size: clamp(10px, 0.5vw, 16px);
  letter-spacing: 0.07em;
  position: relative;
}
.ft a {
  display: block;
  line-height: 2em;
  margin-left: 0.2rem;
  width: 2.5em;
  height: 2em;
  letter-spacing: 0.25em;
  text-align: right;
  padding-right: 0.25rem;
  color: #000000;
}
.ft a:hover {
  width: 2.5em;
  height: 2em;
  color: #ffffff;
  background-color: #377b98;
  z-index: 5;
  position: relative;
}
.ft a:hover::before {
  display: block;
  content: "";
  position: absolute;
  z-index: -2;
  height: 100%;
  width: 0;
  background-color: #f2f6e2;
  animation: n-lines 550ms forwards;
  -o-animation: n-lines 550ms forwards;
  -moz-animation: n-lines 550ms forwards;
  -webkit-animation: n-lines 550ms forwards;
  transition: all ease 600ms;
  -webkit-transition: all ease 600ms;
  -o-transition: all ease 600ms;
  -moz-transition: all ease 600ms;
  -ms-transition: all ease 600ms;
}

/*      *      *      *      H E A D E R     *      *      *      */
.logo {
  grid-area: logo;
  float: left;
  margin-left: 20%;
  max-width: 4em;
  min-width: 3em;
  height: auto;
  user-select: none;
  pointer-events: none;
}

.menu {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  grid-area: nav;
  text-transform: uppercase;
  text-align: left;
  padding: 0em 3.5em;
  border-bottom: 1.5px solid #005B72;
}
.menu a {
  display: block;
  white-space: nowrap;
  color: #9FB0B5;
  color: #24343c;
  width: 100%;
  height: 100%;
}
.menu a:hover {
  color: #bda8a8;
}
.menu a:active {
  color: #81c5ab;
}

.menu ul {
  display: flex;
  flex-wrap: nowrap;
  height: 100%;
  width: 50%;
  list-style-type: none;
  position: relative;
  padding: 0.2em;
  align-items: center;
  justify-content: center;
}

.menu ul li,
.menu li,
.menu .menudown {
  display: inline-block;
  height: 3em;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin: auto;
  cursor: pointer;
  background-color: transparent;
  border-radius: 5px;
  position: relative;
  z-index: 5;
  font-family: "IBM Plex Mono", monospace;
  font-family: "Lat", sans-serif;
  font-weight: 400;
  letter-spacing: 0.5em;
  font-size: clamp(14px, 0.8vw, 0.8vw);
  line-height: 3em;
  margin-right: 10px;
  transition: all ease 500ms both;
  -webkit-transition: all ease 500ms both;
  -moz-transition: all ease 500ms both;
  -ms-transition: all ease 500ms both;
  -o-transition: all ease 500ms both;
}
.menu ul li::before,
.menu li::before,
.menu .menudown::before {
  content: "";
  position: absolute;
  top: 87%;
  left: 25%;
  width: 0;
  overflow: hidden;
  height: 1.5px;
  text-align: center;
  z-index: -2;
  transform: scale(1);
  transition: all ease 450ms;
  -webkit-transition: all ease 450ms;
  -o-transition: all ease 450ms;
  -moz-transition: all ease 450ms;
  -ms-transition: all ease 450ms;
}
.menu ul li::after,
.menu li::after,
.menu .menudown::after {
  content: "";
  position: absolute;
  top: 93%;
  left: 15%;
  width: 0;
  overflow: hidden;
  height: 3px;
  transform: scale(1);
  background-color: #005472;
  text-align: center;
  z-index: -2;
  transition: all ease 450ms;
  -webkit-transition: all ease 450ms;
  -o-transition: all ease 450ms;
  -moz-transition: all ease 450ms;
  -ms-transition: all ease 450ms;
}
.menu ul li:hover,
.menu li:hover,
.menu .menudown:hover {
  color: #bda8a8;
  border-radius: 0;
  z-index: 5;
}
.menu ul li:hover::before,
.menu li:hover::before,
.menu .menudown:hover::before {
  content: "";
  position: absolute;
  top: 87%;
  width: 50%;
  transform: scale(1);
  background-color: #ecc364;
  transition: all ease 450ms;
  -webkit-transition: all ease 450ms;
  -o-transition: all ease 450ms;
  -moz-transition: all ease 450ms;
  -ms-transition: all ease 450ms;
  overflow: hidden;
}
.menu ul li:hover::after,
.menu li:hover::after,
.menu .menudown:hover::after {
  content: "";
  position: absolute;
  top: 93%;
  width: 70%;
  transform: scale(0);
  background-color: #4c9a9f;
  transition: all ease 450ms;
  -webkit-transition: all ease 450ms;
  -o-transition: all ease 450ms;
  -moz-transition: all ease 450ms;
  -ms-transition: all ease 450ms;
  overflow: hidden;
}

.menu ul li:hover,
.menu li:hover {
  position: relative;
}
.menu ul li:hover > ul,
.menu li:hover > ul {
  display: flex !important;
  flex-direction: column;
  position: absolute;
}

.menu ul li ul,
.menu ul ul,
.menu li ul,
.menu sub {
  display: none;
  position: absolute;
  opacity: 1;
  width: 32rem;
  min-width: fit-content;
  height: auto;
  left: 0;
  top: 95%;
  text-align: left;
  transition: all ease 600ms;
  -webkit-transition: all ease 600ms;
  -o-transition: all ease 600ms;
  -moz-transition: all ease 600ms;
  -ms-transition: all ease 600ms;
  padding: 0.4em;
  background-color: #ffffff;
  z-index: 150;
}
.menu ul li ul li,
.menu ul ul li,
.menu li ul li,
.menu sub li {
  clear: both;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  float: left;
  height: 3.5714285714em;
  min-width: 100%;
  white-space: nowrap;
  margin-left: 0.6em;
  margin-bottom: 2px;
  font-family: "NavLit", "Roboto Serif", serif;
  font-family: "Lato", sans-serif;
  font-size: clamp(11px, 0.7vw, 0.7vw);
  font-weight: 300;
  letter-spacing: 0.18em;
  line-height: 3em;
  padding: 0.2em 0.5em;
  position: relative;
  background-color: #f4f6f4;
  border-radius: 0;
  justify-content: left;
  transition: all ease 600ms;
  -webkit-transition: all ease 600ms;
  -o-transition: all ease 600ms;
  -moz-transition: all ease 600ms;
  -ms-transition: all ease 600ms;
  z-index: 5;
  /*
  &::before,
  &::after {
      border-style: solid;
      border-color: #fff;
  }
  */
  /*
          &::before {
              transform: scaleX(0);
              border-width: 2px 0 2px 0;
          }

          &::after {
              transform: scaleY(0);
              border-width: 0 2px 0 2px;
          }
  */
}
.menu ul li ul li::before,
.menu ul ul li::before,
.menu li ul li::before,
.menu sub li::before {
  display: block;
  content: "";
  width: 100%;
  height: 2.8409090909em;
  left: 0;
  top: 0;
  overflow: hidden;
  position: absolute;
  transition: all ease 600ms;
  -webkit-transition: all ease 600ms;
  -o-transition: all ease 600ms;
  -moz-transition: all ease 600ms;
  -ms-transition: all ease 600ms;
  z-index: -2;
}
.menu ul li ul li::after,
.menu ul ul li::after,
.menu li ul li::after,
.menu sub li::after {
  display: none;
}
.menu ul li ul li:hover::before,
.menu ul ul li:hover::before,
.menu li ul li:hover::before,
.menu sub li:hover::before {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  background-image: linear-gradient(to right, #e04e8b, #f38d64);
  background-image: -o-linear-gradient(to right, #e04e8b, #f38d64);
  background-image: -moz-linear-gradient(to right, #e04e8b, #f38d64);
  background-image: -webkit-linear-gradient(to right, #e04e8b, #f38d64);
}
.menu ul li ul li:hover::before,
.menu ul ul li:hover::before,
.menu li ul li:hover::before,
.menu sub li:hover::before {
  border-color: #fff;
}
.menu ul li ul li:hover::after,
.menu ul ul li:hover::after,
.menu li ul li:hover::after,
.menu sub li:hover::after {
  border-width: 2px 0 2px 0;
  transform: scale(1, 0);
}
.menu ul li ul li a,
.menu ul ul li a,
.menu li ul li a,
.menu sub li a {
  display: inline-block;
  width: 100%;
  height: 100%;
  text-align: left;
  white-space: nowrap;
  color: #24343c;
  padding-left: 0.3em;
}
.menu ul li ul li a:hover,
.menu ul ul li a:hover,
.menu li ul li a:hover,
.menu sub li a:hover {
  color: #fff;
}

#outer_min {
  display: none;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: transparent;
}

.min_nav {
  display: none;
  opacity: 0;
}

#min_btn {
  width: 4em;
  max-width: 4em;
  height: 4em;
  margin-left: 90%;
  align-items: center;
  justify-content: center;
  padding: 0.1em;
  z-index: 9;
  background-color: #d1e6e6;
  background-image: url(../assets/mn.svg);
  background-size: 60%;
  border-radius: 5px;
  background-repeat: no-repeat;
  background-position: center;
}
#min_btn:hover {
  background-color: #eeda81;
}

#min_navdown,
.media_nav {
  display: flex;
  flex-wrap: wrap;
  width: 65%;
  height: auto;
  position: fixed;
  background-color: #f8f8f8;
  padding: 0.2em;
  right: 4.2%;
  margin-top: 0.1%;
  color: #000;
  text-transform: uppercase;
  letter-spacing: 0.4rem;
  font-size: 2vmax;
  text-align: right;
  font-weight: 250;
}
#min_navdown a,
.media_nav a {
  white-space: nowrap;
  color: #00325a;
}
#min_navdown a:hover,
.media_nav a:hover {
  color: #e8984c;
  font-weight: 200;
}
#min_navdown a:active,
.media_nav a:active {
  color: #b3c8c3;
}
#min_navdown ul,
.media_nav ul {
  display: flex;
  flex-direction: column;
  width: 97%;
  height: auto;
  right: 0;
  top: 2%;
  list-style-type: none;
  overflow: visible;
  -webkit-overflow-scrolling: auto;
  padding: 0.2em 0.1em;
  position: relative;
  margin: 0 auto;
  z-index: 99;
}
#min_navdown ul li,
#min_navdown li,
#min_navdown .min_menudown,
#min_navdown .min_a,
#min_navdown .min_b,
.media_nav ul li,
.media_nav li,
.media_nav .min_menudown,
.media_nav .min_a,
.media_nav .min_b {
  display: block;
  height: 5rem;
  width: 100%;
  padding: 0.2em;
  text-align: right;
  align-items: center;
  justify-content: right;
  line-height: 4.5em;
  cursor: pointer;
  color: #005B72;
  background-color: #f8f8f8;
  margin-bottom: 5px;
  position: relative;
  font-family: "IBM Plex Mono", "Roboto Mono", monospace;
  font-family: "Lat", sans-serif;
  font-weight: 200;
  font-size: 2.5vmax;
  transition: all ease 600ms both;
  -webkit-transition: all ease 600ms both;
  -o-transition: all ease 600ms both;
  -moz-transition: all ease 600ms both;
  -ms-transition: all ease 600ms both;
}
#min_navdown ul li:not(.btn)::after,
#min_navdown li:not(.btn)::after,
#min_navdown .min_menudown:not(.btn)::after,
#min_navdown .min_a:not(.btn)::after,
#min_navdown .min_b:not(.btn)::after,
.media_nav ul li:not(.btn)::after,
.media_nav li:not(.btn)::after,
.media_nav .min_menudown:not(.btn)::after,
.media_nav .min_a:not(.btn)::after,
.media_nav .min_b:not(.btn)::after {
  display: block;
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  margin-right: 0;
  bottom: 0;
  background-color: #a1cac6;
}
#min_navdown ul li:hover,
#min_navdown li:hover,
#min_navdown .min_menudown:hover,
#min_navdown .min_a:hover,
#min_navdown .min_b:hover,
.media_nav ul li:hover,
.media_nav li:hover,
.media_nav .min_menudown:hover,
.media_nav .min_a:hover,
.media_nav .min_b:hover {
  color: #e8984c;
  font-weight: 200;
}
#min_navdown ul li:hover:not(.btn)::after,
#min_navdown li:hover:not(.btn)::after,
#min_navdown .min_menudown:hover:not(.btn)::after,
#min_navdown .min_a:hover:not(.btn)::after,
#min_navdown .min_b:hover:not(.btn)::after,
.media_nav ul li:hover:not(.btn)::after,
.media_nav li:hover:not(.btn)::after,
.media_nav .min_menudown:hover:not(.btn)::after,
.media_nav .min_a:hover:not(.btn)::after,
.media_nav .min_b:hover:not(.btn)::after {
  display: block;
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  margin-right: 0;
  bottom: 0;
  background-color: #c4ddd7;
}
#min_navdown ul li:hover > ul,
#min_navdown li:hover > ul,
#min_navdown .min_menudown:hover > ul,
#min_navdown .min_a:hover > ul,
#min_navdown .min_b:hover > ul,
.media_nav ul li:hover > ul,
.media_nav li:hover > ul,
.media_nav .min_menudown:hover > ul,
.media_nav .min_a:hover > ul,
.media_nav .min_b:hover > ul {
  display: inline-block !important;
}
#min_navdown ul li ul,
#min_navdown ul ul,
#min_navdown li ul,
#min_navdown sub,
.media_nav ul li ul,
.media_nav ul ul,
.media_nav li ul,
.media_nav sub {
  display: block;
  position: relative;
  opacity: 1;
  width: 100%;
  height: 100%;
  text-align: right;
  transition: all ease 600ms;
  -webkit-transition: all ease 600ms;
  -o-transition: all ease 600ms;
  -moz-transition: all ease 600ms;
  -ms-transition: all ease 600ms;
  padding: 0.3em;
  z-index: 150;
}
#min_navdown ul li ul li,
#min_navdown ul ul li,
#min_navdown li ul li,
#min_navdown sub li,
.media_nav ul li ul li,
.media_nav ul ul li,
.media_nav li ul li,
.media_nav sub li {
  clear: both;
  display: flex;
  float: right;
  height: 2.4038461538em;
  width: 99.5%;
  white-space: nowrap;
  color: #005B72;
  margin-right: 0.5rem;
  margin-bottom: 5px;
  font-weight: 300;
  padding: 0.1em;
  position: relative;
  background-color: #f3f5f1;
  border-radius: 0;
  justify-content: right;
  transition: all ease 600ms;
  -webkit-transition: all ease 600ms;
  -o-transition: all ease 600ms;
  -moz-transition: all ease 600ms;
  -ms-transition: all ease 600ms;
  z-index: 5;
}
#min_navdown ul li ul li::before, #min_navdown ul li ul li::after,
#min_navdown ul ul li::before,
#min_navdown ul ul li::after,
#min_navdown li ul li::before,
#min_navdown li ul li::after,
#min_navdown sub li::before,
#min_navdown sub li::after,
.media_nav ul li ul li::before,
.media_nav ul li ul li::after,
.media_nav ul ul li::before,
.media_nav ul ul li::after,
.media_nav li ul li::before,
.media_nav li ul li::after,
.media_nav sub li::before,
.media_nav sub li::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  overflow: hidden;
  z-index: -2;
}
#min_navdown ul li ul li::before,
#min_navdown ul ul li::before,
#min_navdown li ul li::before,
#min_navdown sub li::before,
.media_nav ul li ul li::before,
.media_nav ul ul li::before,
.media_nav li ul li::before,
.media_nav sub li::before {
  transform: scaleX(0);
  border-width: 2px 0 2px 0;
}
#min_navdown ul li ul li::after,
#min_navdown ul ul li::after,
#min_navdown li ul li::after,
#min_navdown sub li::after,
.media_nav ul li ul li::after,
.media_nav ul ul li::after,
.media_nav li ul li::after,
.media_nav sub li::after {
  transform: scaleY(0);
  border-width: 0 2px 0 2px;
}
#min_navdown ul li ul li:hover,
#min_navdown ul ul li:hover,
#min_navdown li ul li:hover,
#min_navdown sub li:hover,
.media_nav ul li ul li:hover,
.media_nav ul ul li:hover,
.media_nav li ul li:hover,
.media_nav sub li:hover {
  transition: all ease 600ms;
  -webkit-transition: all ease 600ms;
  -o-transition: all ease 600ms;
  -moz-transition: all ease 600ms;
  -ms-transition: all ease 600ms;
  border-color: #000;
}
#min_navdown ul li ul li:hover::before, #min_navdown ul li ul li:hover::after,
#min_navdown ul ul li:hover::before,
#min_navdown ul ul li:hover::after,
#min_navdown li ul li:hover::before,
#min_navdown li ul li:hover::after,
#min_navdown sub li:hover::before,
#min_navdown sub li:hover::after,
.media_nav ul li ul li:hover::before,
.media_nav ul li ul li:hover::after,
.media_nav ul ul li:hover::before,
.media_nav ul ul li:hover::after,
.media_nav li ul li:hover::before,
.media_nav li ul li:hover::after,
.media_nav sub li:hover::before,
.media_nav sub li:hover::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  border-color: #f0c74d;
  background-color: #005B72;
  overflow: hidden;
  z-index: -2;
  transform: scale(1, 1);
}
#min_navdown ul li ul li:hover::before,
#min_navdown ul ul li:hover::before,
#min_navdown li ul li:hover::before,
#min_navdown sub li:hover::before,
.media_nav ul li ul li:hover::before,
.media_nav ul ul li:hover::before,
.media_nav li ul li:hover::before,
.media_nav sub li:hover::before {
  transform: scaleX(0);
  border-width: 2px 0 2px 0;
}
#min_navdown ul li ul li:hover::after,
#min_navdown ul ul li:hover::after,
#min_navdown li ul li:hover::after,
#min_navdown sub li:hover::after,
.media_nav ul li ul li:hover::after,
.media_nav ul ul li:hover::after,
.media_nav li ul li:hover::after,
.media_nav sub li:hover::after {
  transform: scaleY(0);
  border-width: 0 2px 0 2px;
}
#min_navdown ul li ul li a,
#min_navdown ul ul li a,
#min_navdown li ul li a,
#min_navdown sub li a,
.media_nav ul li ul li a,
.media_nav ul ul li a,
.media_nav li ul li a,
.media_nav sub li a {
  display: inline-block;
  width: 100%;
  height: 100%;
  text-align: end;
  margin-right: 0.2em;
  white-space: nowrap;
  color: #46aaae;
  font-size: clamp(10px, 2.5vw, 28px);
  font-family: "Literata", serif;
  font-weight: 100;
  line-height: 3.5em;
  letter-spacing: 0.2em;
  padding-right: 0.3em;
}
#min_navdown ul li ul li a:hover,
#min_navdown ul ul li a:hover,
#min_navdown li ul li a:hover,
#min_navdown sub li a:hover,
.media_nav ul li ul li a:hover,
.media_nav ul ul li a:hover,
.media_nav li ul li a:hover,
.media_nav sub li a:hover {
  font-weight: 300;
  color: #fff;
}
#min_navdown ul li ul li a:active,
#min_navdown ul ul li a:active,
#min_navdown li ul li a:active,
#min_navdown sub li a:active,
.media_nav ul li ul li a:active,
.media_nav ul ul li a:active,
.media_nav li ul li a:active,
.media_nav sub li a:active {
  color: #b3c8c3;
}

/*      *      *      *      M A I N     *      *      *      */
.outer_slides {
  display: flex;
  width: 100%;
  max-width: 100%;
  min-height: 70vh;
  padding: 0;
  margin: auto;
  align-items: center;
  text-align: left;
  justify-content: center;
  overflow: hidden;
}

#inner_slides {
  display: grid;
  gap: 0.1rem;
  margin: 0;
  width: 100%;
  max-width: 100%;
  height: 100%;
  grid-template-columns: 0.55fr 0.45fr;
  grid-template-rows: auto;
  grid-template-areas: "txt im";
  align-items: center;
  justify-content: center;
  z-index: 1;
  transition: ease 6000ms;
  -webkit-transition: ease 6000ms;
  -o-transition: ease 6000ms;
  -moz-transition: ease 6000ms;
  -ms-transition: ease 6000ms;
  overflow-y: auto;
  scroll-behavior: smooth;
}

#outer_qt {
  display: none;
  width: 100%;
  height: 100%;
  grid-template-columns: 1fr;
  grid-template-columns: "txt";
  font-family: "BKN", serif;
  font-size: 10.5vmin;
  font-weight: 100;
  padding: 2rem;
  margin: 0 auto;
}

.slide_img {
  display: block;
  grid-area: im;
  width: 57%;
  max-width: 60%;
  height: 80%;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  margin: auto;
  position: relative;
  overflow: hidden;
  user-select: none;
  pointer-events: none;
  transition: all ease;
}

h4 {
  display: block;
  width: 100%;
  text-align: left;
  font-family: "SL";
  font-weight: 100;
  font-size: clamp(25px, 5vw, 5vw);
  text-transform: uppercase;
  letter-spacing: 0.01em;
  color: #000;
  line-height: 0.75em;
  padding: 0.3rem 0.5rem;
  justify-content: flex-start;
  overflow: visible;
  background-image: linear-gradient(60deg, #037481 50%, #03405a 70%);
  background-image: -moz-linear-gradient(60deg, #037481 50%, #03405a 70%);
  background-image: -webkit-linear-gradient(60deg, #037481 50%, #03405a 70%);
  background-image: -o-linear-gradient(60deg, #037481 50%, #03405a 70%);
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -moz-background-clip: text;
  -webkit-background-clip: text;
  position: relative;
  z-index: 5;
}

.events {
  display: block;
  margin-bottom: 1em;
}
.events .events_item {
  font-weight: 500;
  padding: 1em;
  width: 50%;
  text-transform: uppercase;
  font-size: clamp(10px, 1.1vw, 22px);
}
.events li {
  text-transform: none;
  list-style: none;
  display: block;
  font-weight: 400;
  font-size: clamp(10px, 0.9vw, 18px);
  margin-left: 3em;
}

.ti_img {
  max-width: 90%;
}

.main_qt {
  display: block;
  grid-area: txt;
  width: 100%;
  height: auto;
  max-height: 100%;
  font-size: clamp(14px, 0.75vw, 30px);
  text-transform: none;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  line-height: 1.55em;
  padding: 10.5rem 0.2rem;
  margin-left: 14.5%;
  margin-top: auto;
  color: #525252;
  position: relative;
  align-items: center;
  justify-content: center;
}

.words {
  padding: 1.5rem 0.5rem;
}

.content_main {
  display: grid;
  grid-template-columns: 1fr 1.8fr;
  grid-template-rows: auto;
  grid-template-areas: "img txt";
  gap: 0.1em;
  margin: 0;
  padding: 0;
  align-items: center;
  justify-content: center;
  z-index: 1;
  position: relative;
  transition: all 500ms;
  -webkit-transition: all ease 500ms;
  -o-transition: all ease 500ms;
  -moz-transition: all ease 500ms;
  -ms-transition: all ease 500ms;
}

#cm {
  display: none;
  content-visibility: hidden;
}

.inner {
  grid-area: txt;
  font-weight: 300;
  font-family: "IBM Plex Mono", monospace;
  font-family: "Lat", sans-serif;
  font-size: 2.1vmin;
  line-height: 1.4em;
  letter-spacing: -0.015em;
  color: #323334;
  text-transform: none;
  padding: 0;
  position: relative;
  margin: 0 auto;
  overflow-x: visible;
  align-items: flex-end;
  justify-content: center;
  z-index: 5;
}

.inner_img {
  grid-area: img;
  max-width: 85%;
  float: right;
  height: auto;
  position: relative;
  background-image: none;
  margin: 0 auto;
  text-align: center;
  align-items: flex-start;
  justify-content: center;
  overflow: visible;
  user-select: none;
  pointer-events: none;
  z-index: 9;
}

h3 {
  text-align: left;
  font-family: "MM", "Literata", serif;
  font-weight: 500;
  font-size: 21.5vmin;
  text-transform: uppercase;
  letter-spacing: -0.06em;
  color: #000;
  line-height: 0.86em;
  padding: 1rem 1.2rem;
  padding-right: 0.02rem;
  margin: auto;
  margin-left: 2%;
  text-align: left;
  margin-bottom: 2%;
  overflow: visible;
  background-image: linear-gradient(-60deg, #9dcfcf 40%, #afdada 50%, #ddd8c2 95%);
  background-image: -moz-linear-gradient(60deg, #f9ea95 30%, #9dcfcf 50%, #afdada 90%, #dcdad2 95%);
  background-image: -webkit-linear-gradient(60deg, #f9ea95 30%, #9dcfcf 50%, #afdada 90%, #dcdad2 95%);
  background-image: -o-linear-gradient(60deg, #f9ea95 30%, #9dcfcf 50%, #afdada 90%, #dcdad2 95%);
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -moz-background-clip: text;
  -webkit-background-clip: text;
  position: relative;
  z-index: 15;
}
h3::after {
  content: "";
  height: 0.05em;
  max-width: 3.5em;
  position: absolute;
  left: 0%;
  bottom: 0.1%;
  background-color: #2ba7a7;
  background-image: linear-gradient(60deg, #dcf8f3 40%, #d5eae6 60%);
  background-image: -moz-linear-gradient(-60deg, #2ba7a7 40%, #157096 60%);
  background-image: -webkit-linear-gradient(-60deg, #2ba7a7 40%, #157096 60%);
  background-image: -o-linear-gradient(-60deg, #2ba7a7 40%, #157096 60%);
}

._fill {
  max-width: 90%;
  display: flex;
  padding: 0.6rem;
  margin-left: 0;
}

._i {
  margin: auto;
  float: right;
  height: 100%;
  text-align: left;
  align-items: flex-end;
  justify-content: flex-end;
  font-family: "IBM Plex Mono", monospace;
  font-family: "Lat", sans-serif;
  font-size: 1.9vmin;
  font-weight: 400;
  line-height: 1.5em;
  letter-spacing: -0.01em;
}

.media_fill {
  padding: 2em;
  float: right;
  margin-left: 5em;
  align-items: flex-end;
  justify-content: flex-end;
  margin-right: 0;
}

.media_nav {
  display: inline-block;
  width: 100%;
  position: relative;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  padding-top: 0.2em;
  padding-right: 0.2em;
  font-size: 2vmin;
  font-weight: 100;
  color: #686868;
  font-family: "Lora", monospace;
  font-family: "Lat", sans-serif;
  letter-spacing: 0.2em;
  border-right: 10px solid #ff8a70;
}
.media_nav:hover {
  border-right: 10px solid #025b74;
}
.media_nav ._a {
  display: grid;
  text-transform: uppercase;
  padding: 0.5em;
  padding-right: 0.5rem;
  font-size: 1.5vmin;
  font-weight: 300;
  cursor: pointer;
  border-bottom: 2px solid #f69c80;
  letter-spacing: 0.23em;
  color: #0d617b;
}
.media_nav ._a:hover {
  background-color: #f9fbfb;
  color: #ed86a6;
  border-bottom: 2px solid #d1e8e6;
}
.media_nav a,
.media_nav li a {
  display: block;
  list-style: inside;
  font-family: "IBM Plex Mono", "Roboto Mono", monospace;
  font-family: "Lat", sans-serif;
  text-transform: none;
  letter-spacing: 0.3rem;
  font-size: 1.4vmin;
  font-weight: 400;
  padding: 0.3rem;
  color: #fe7d7d;
  background-color: #f4f6f6;
  border-bottom: 1px solid #70d1c9;
  text-align: right;
  border: 0;
}
.media_nav a::before,
.media_nav li a::before {
  content: "> ";
}
.media_nav a:hover,
.media_nav li a:hover {
  background-color: #017b84;
  color: #ffe100;
  font-weight: 300;
}

.caps {
  display: block;
  font-size: 1.2vw;
  letter-spacing: 0.5rem;
  text-transform: uppercase;
  text-align: right;
  padding: 0.5rem;
  margin-top: -0.5%;
  color: #c65275;
}

.book_list {
  width: 76%;
  height: 60%;
  padding: 0.5em;
  border: 5px solid #2fb7cf;
  text-align: center;
  margin: 0 auto;
  z-index: 10;
  position: fixed;
  left: 12%;
  overflow: hidden;
  transition: ease 200ms;
  -webkit-transition: ease 200ms;
  -o-transition: ease 200ms;
  -moz-transition: ease 200ms;
  -ms-transition: ease 200ms;
}

._bk {
  display: block;
  width: 100%;
  height: 100%;
}

._mini {
  display: none;
}

._mini_words {
  display: none;
}

.slide_dots {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: space-between;
  width: 150px;
  height: 60px;
  margin: auto;
  padding: 0.5rem;
  position: absolute;
  bottom: 0.5%;
  left: calc(50% - 75px);
  z-index: 100;
}

.slide_dot {
  display: block;
  float: left;
  cursor: pointer;
  text-align: center;
  width: 10px;
  height: 10px;
  background-color: #edefe8;
  opacity: 0.9;
  border-radius: 50%;
}
.slide_dot:not(:last-child) {
  margin-right: 1rem;
}
.slide_dot:active {
  background-color: #9ae6c5;
}
.slide_dot:hover {
  background-color: #fd9494;
}
.slide_dot:nth-child(1) {
  background-color: #D9D9D9;
}
.slide_dot:nth-child(2) {
  background-color: #FDDB9B;
}
.slide_dot:nth-child(3) {
  background-color: #BDD9E1;
}

#links_nav {
  display: block;
  height: 100%;
  max-width: 100%;
  color: #000;
  text-align: left;
  align-items: center;
  justify-content: center;
  margin: auto;
  padding: 3rem 0.5rem;
  position: relative;
}

.buttons,
.buttons:link,
.buttons:active {
  display: inline-block;
  color: #000;
  border-radius: 2px;
  height: 3em;
  max-width: clamp(fit-content, 1.5vw, 35rem);
  max-width: clamp(fit-content, 1.5vw, 25rem);
  width: 10em;
  margin: auto;
  text-transform: uppercase;
  font-family: "Lato", sans-serif;
  font-size: clamp(10px, 0.85vw, 0.7vw);
  letter-spacing: 0.06rem;
  line-height: 1.5em;
  padding: 0.7em 1.5em;
  font-weight: 400;
  margin-top: 5px;
  border-radius: 2px;
  position: relative;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.buttons::before, .buttons::after,
.buttons:link::before,
.buttons:link::after,
.buttons:active::before,
.buttons:active::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  overflow: hidden;
  z-index: -2;
  border-style: solid;
  border-color: #f0c74d;
  transition: all ease 650ms;
  -webkit-transition: all ease 650ms;
  -o-transition: all ease 650ms;
  -moz-transition: all ease 650ms;
  -ms-transition: all ease 650ms;
}
.buttons::before,
.buttons:link::before,
.buttons:active::before {
  transform: scaleX(0);
  border-width: 2px 0 2px 0;
}
.buttons::after,
.buttons:link::after,
.buttons:active::after {
  transform: scaleY(0);
  border-width: 0 2px 0 2px;
}
.buttons:nth-child(2),
.buttons:link:nth-child(2),
.buttons:active:nth-child(2) {
  background-color: #005b72;
  background-color: rgba(0, 91, 114, 0.0392156863);
  background-color: rgba(0, 91, 114, 0.0509803922);
  text-transform: none;
  max-width: 20rem;
  color: #ffffff;
  color: #005b72;
}
.buttons:nth-child(2):hover,
.buttons:link:nth-child(2):hover,
.buttons:active:nth-child(2):hover {
  color: #f8bc4d;
}
.buttons:nth-child(1),
.buttons:link:nth-child(1),
.buttons:active:nth-child(1) {
  background-color: #f8bc4d;
  background-color: #005b72;
  max-width: 18rem;
  margin-right: 0.5em;
  color: #313232;
  color: #ffffff;
}
.buttons:nth-child(1):hover,
.buttons:link:nth-child(1):hover,
.buttons:active:nth-child(1):hover {
  color: #23857f;
  color: #ffffff;
  background: linear-gradient(to right, #e04e8b, #f38d64);
}
.buttons:hover,
.buttons:link:hover,
.buttons:active:hover {
  color: #0e2c5f;
  border-radius: 5px;
  border: 0px solid #000;
  position: relative;
  background-color: transparent;
  transition: all ease 450ms 50ms;
  -webkit-transition: all ease 450ms 50ms;
  -o-transition: all ease 450ms 50ms;
  -moz-transition: all ease 450ms 50ms;
  -ms-transition: all ease 450ms 50ms;
  z-index: 5;
}
.buttons:hover::before, .buttons:hover::after,
.buttons:link:hover::before,
.buttons:link:hover::after,
.buttons:active:hover::before,
.buttons:active:hover::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  border-color: #f8bc4d;
  overflow: hidden;
  z-index: -2;
  transform: scale(1, 1);
  transition: all ease 450ms;
  -webkit-transition: all ease 450ms;
  -o-transition: all ease 450ms;
  -moz-transition: all ease 450ms;
  -ms-transition: all ease 450ms;
}

.inner_cover {
  grid-area: img;
  max-width: 75%;
  height: auto;
  margin-left: 5%;
  margin-top: 5%;
  padding: 0;
  position: relative;
  overflow: hidden;
  user-select: none;
  pointer-events: none;
  z-index: 9;
}

.bk_inner {
  display: flex;
  flex-direction: column;
  float: left;
  width: 95%;
  max-width: 95%;
  max-height: 100%;
  padding: 1rem 0.5rem;
  font-size: clamp(12px, 0.65vw, 20px);
  font-weight: 400;
  line-height: 1.6em;
  text-transform: none;
  font-family: "IBM Plex Mono", monospace;
  font-family: "Lat", sans-serif;
}
.bk_inner p {
  padding: 0.3% 0.7%;
}

.inner_bks {
  display: flex;
  flex-flow: column wrap;
  max-width: 100%;
  max-height: 100%;
  grid-area: txt;
  letter-spacing: -0.015em;
  color: #323334;
  text-transform: none;
  padding: 0em 0.5em;
  margin-left: -12%;
  align-items: center;
  justify-content: center;
  z-index: 5;
}

h1 {
  display: block;
  float: right;
  font-family: "BKN", serif;
  font-size: 10.5vmin;
  font-weight: 100;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  text-align: center;
  color: #032b4e;
  line-height: 0.71em;
  padding: 0.4em 0.2em;
  padding-right: 0.3em;
  position: relative;
  z-index: 5;
  overflow: visible;
  background-image: linear-gradient(-60deg, #f1f1be, #88c3c2);
  background-image: -moz-linear-gradient(-60deg, #f1f1be, #88c3c2);
  background-image: -webkit-linear-gradient(-60deg, #f1f1be, #88c3c2);
  background-image: -o-linear-gradient(-60deg, #f1f1be, #88c3c2);
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -moz-background-clip: text;
  -webkit-background-clip: text;
}

h2 {
  display: inline-block;
  width: 100%;
  font-family: "BKN", serif;
  font-weight: 100;
  font-size: clamp(30px, 6vw, 80px);
  text-transform: uppercase;
  letter-spacing: 0.04em;
  text-align: right;
  color: #032b4e;
  line-height: 0.71em;
  padding: 0.45em 0.2em;
  padding-right: 0.3em;
  position: relative;
  z-index: 5;
  overflow: visible;
  background-image: linear-gradient(-60deg, #f1f1be, #88c3c2);
  background-image: -moz-linear-gradient(-60deg, #f1f1be, #88c3c2);
  background-image: -webkit-linear-gradient(-60deg, #f1f1be, #88c3c2);
  background-image: -o-linear-gradient(-60deg, #f1f1be, #88c3c2);
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -moz-background-clip: text;
  -webkit-background-clip: text;
}

h5 {
  display: none;
}

.b_box {
  display: block;
  height: 100%;
  width: 100%;
  max-width: 100%;
  padding: 0.01em;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  margin-top: 1.5%;
  position: relative;
  animation: hider 3000ms forwards 800ms;
  -o-animation: hider 3000ms forwards 800ms;
  -moz-animation: hider 3000ms forwards 800ms;
  -webkit-animation: hider 3000ms forwards 800ms;
}

#h8 {
  display: block;
  float: right;
  font-family: "IBM Plex Mono", serif;
  font-family: "Lat", sans-serif;
  line-height: 0.9em;
  font-size: 1.8vmin;
  font-weight: 400;
  width: 100%;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  text-align: right;
  align-items: center;
  margin: 0 auto;
  justify-content: right;
  position: relative;
  color: #76b3ac;
  padding: 0.8em 1.5em;
  min-height: 100%;
  background-color: #f2f9f9;
  background-image: linear-gradient(-60deg, #f2f5f5, #cfe7e7, #f2f9f9);
  z-index: 5;
}

.b_info {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-auto-flow: dense;
  max-width: 95%;
  height: 100%;
  margin-left: 3.1%;
  margin-bottom: 2rem;
  align-items: flex-end;
  justify-content: flex-end;
  position: relative;
  /*
  &:hover {
      border-bottom: 5px solid #ffa083;
  }
  */
}

.b_links {
  display: flex;
  flex-flow: column wrap;
  flex: auto;
  font-size: clamp(10px, 0.6vw, 18px);
  letter-spacing: 0.2rem;
  font-family: "Literata", serif;
  line-height: 0.95em;
  font-weight: 300;
  height: 2em;
  text-transform: uppercase;
  color: #005B72;
  background-color: transparent;
  background-color: #f7fcfc;
  padding: 1.5rem 1.9rem;
  position: relative;
  text-align: left;
  align-items: flex-end;
  justify-content: center;
}
.b_links:hover {
  color: #ffeda6;
  background-color: #175c7c;
  position: relative;
  z-index: 5;
}
.b_links:hover::before {
  content: "";
  position: absolute;
  top: 0;
  height: 100%;
  animation: l-lines 500ms forwards;
  -o-animation: l-lines 500ms forwards;
  -moz-animation: l-lines 500ms forwards;
  -webkit-animation: l-lines 500ms forwards;
  transition: all ease 550ms;
  -webkit-transition: all ease 550ms;
  -o-transition: all ease 550ms;
  -moz-transition: all ease 550ms;
  -ms-transition: all ease 550ms;
  z-index: -2;
}

#b_rev,
#b_rev_ {
  display: flex;
  width: 90%;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 3em;
}

#b_rev ._bx {
  display: block;
  float: left;
  width: 25%;
  font-size: clamp(10px, 0.7vw, 20px);
  line-height: 1.3em;
  letter-spacing: 0;
  text-align: left;
  padding: 0.3rem 0.7rem;
}
#b_rev ._bx ._b {
  display: inline-block;
}
#b_rev ._bx i {
  margin-left: 0.2rem;
  font-size: clamp(9px, 0.65vw, 14px);
  line-height: 0.9em;
}

#b_rev_ {
  margin: 0 auto;
  margin-bottom: 1rem;
  justify-content: space-between;
  color: #03405a;
  font-family: "Literata", serif;
  font-weight: 300;
  line-height: 1em;
  letter-spacing: 0.02em;
  font-size: clamp(12px, 0.6vw, 16px);
}
#b_rev_ ._bx {
  margin-right: 1rem;
  text-align: end;
}
#b_rev_ ._bx i {
  color: #24343c;
}

h6 {
  display: inline-block;
  font-family: "Literata", serif;
  font-size: 2.7vw;
  font-weight: 300;
  font-style: italic;
  letter-spacing: -0.05rem;
  text-align: left;
  width: 51%;
  color: #101010;
  line-height: 0.8em;
  padding: 0.9em 0.5em;
  margin-left: 41%;
  z-index: 5;
}

/*      *      *      *      M I S C     *      *      *      */
#fm {
  display: block;
  float: right;
  font-family: "IBM Plex Mono";
  font-size: 1.35vmin;
  font-weight: 350;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  line-height: 1.4em;
  margin: 0;
  background-color: rgba(253, 253, 253, 0.8078431373);
  padding: 2em;
  align-items: flex-end;
  justify-content: flex-end;
}

.field_err {
  font-family: "IBM Plex Mono";
  font-size: 0.65vw;
  display: inline-flex;
  flex-wrap: wrap;
  float: right;
  padding: 0.6em;
  color: #dc1f2b;
  line-height: 0.9em;
  letter-spacing: 0.05em;
  font-weight: 700;
  font-family: "IBM Plex Mono", monospace;
}

label {
  display: inline;
  height: 1em;
  font-family: "Literata", serif;
  font-weight: 400;
  font-size: 0.7vw;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #a1c6c8;
  line-height: 0.7em;
  padding: 0.2em;
  background-image: linear-gradient(to right, #ba94b8, #a1c6c8);
  overflow: hidden;
  background-clip: text;
  -moz-background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

input[type=text],
textarea {
  display: inline-block;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 2em;
  padding: 0.15rem 0.5rem;
  color: #ffffff;
  line-height: 0.9em;
  font-size: 0.65vw;
  font-family: "IBM Plex Mono", monospace;
  border: 1px solid #bad3d0;
  background-image: linear-gradient(to right, #f8f8f5, #f8f7f6);
  position: relative;
}
input[type=text]:hover,
textarea:hover {
  background-image: linear-gradient(to right, #fffddd, #f5ede7);
}
input[type=text]:focus,
textarea:focus {
  background-image: linear-gradient(60deg, #ffd4ff, #f6d3d8);
  color: #000000;
  font-weight: 500;
}

textarea {
  padding: 0.6em;
}

input[type=submit] {
  display: block;
  width: 12em;
  height: 2.5rem;
  color: #f9e1ad;
  border-radius: 2px;
  background-color: #a97ac9;
  text-transform: uppercase;
  text-align: center;
  font-family: "Literata", serif;
  font-size: 0.8vw;
  letter-spacing: 0.4rem;
  line-height: 1em;
  font-weight: 300;
  border: 1.5px solid #a774ad;
}
input[type=submit]:hover {
  width: 100%;
  color: #fff;
  font-family: "IBM Plex Mono", monospace;
  font-size: 1.4vw;
  font-weight: 300;
  letter-spacing: 0.25rem;
  background-color: #F450F8;
  background-image: linear-gradient(60deg, #d319d6, #f15368);
  border: 0px solid #000;
  transition: all ease 300ms;
  -webkit-transition: all ease 300ms;
  -o-transition: all ease 300ms;
  -moz-transition: all ease 300ms;
  -ms-transition: all ease 300ms;
}

.inner_error {
  grid-area: txt;
  float: right;
  min-width: 100%;
  max-height: 100%;
  color: #DDDDDE;
  font-family: "Literata", serif;
  font-size: 5vmax;
  font-weight: 100;
  font-style: italic;
  line-height: 0.85em;
  text-align: right;
  margin: auto;
  padding: 1em;
  overflow: hidden;
}

.error_button,
.error_button:link {
  display: block;
  min-width: fit-content;
  height: auto;
  line-height: 1em;
  max-width: 50%;
  border-radius: 4px;
  padding: 1rem;
  color: #666666;
  text-transform: uppercase;
  text-align: center;
  margin: auto;
  font-family: "ErrF", "Lato", sans-serif;
  font-size: 2vmax;
  font-weight: 100;
  letter-spacing: 0.5rem;
  background-color: rgba(249, 249, 249, 0.8117647059);
  position: relative;
  z-index: 5;
  border: 1px solid #f6f6f6;
}
.error_button:hover,
.error_button:link:hover {
  color: #fff;
  background-color: #91c9c3;
}
.error_button:hover::before,
.error_button:link:hover::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 0;
  top: 0;
  overflow: hidden;
  z-index: -2;
  transition: all ease 550ms;
  -webkit-transition: all ease 550ms;
  -o-transition: all ease 550ms;
  -moz-transition: all ease 550ms;
  -ms-transition: all ease 550ms;
  animation: b-lines 500ms forwards;
  -o-animation: b-lines 500ms forwards;
  -moz-animation: b-lines 500ms forwards;
  -webkit-animation: b-lines 500ms forwards;
}

#canv {
  width: 100%;
  height: 100%;
  z-index: 10;
  position: fixed;
  border: 0px solid #ffac47;
  background: transparent;
  overflow: hidden;
}

#canv_bg {
  width: 80%;
  height: 66%;
  position: fixed;
  margin-left: 0.5%;
  margin-top: 0.5%;
  border: 0px solid #ffac47;
  background: transparent;
  overflow: hidden;
}

/*      *      *      *      K E Y F R A M E S     *      *      *      */
@supports not (text-underline-offset: auto) {
  li:hover {
    -webkit-text-decoration: underline #5bafa3;
    text-decoration-line: underline #5bafa3;
    -moz-text-decoration-line: underline;
    text-decoration: underline #5bafa3;
    -webkit-text-decoration-skip-ink: none;
    text-decoration-skip-ink: none;
    text-decoration-thickness: 0.4em;
    text-underline-offset: -0.2em;
    color: #ffe14c;
    transition: all 500ms ease;
    -webkit-transition: all ease 500ms;
    -o-transition: all ease 500ms;
    -moz-transition: all ease 500ms;
    -ms-transition: all ease 500ms;
  }
  li a:active {
    background: #b5d4d1;
    color: #ff05d1;
  }
}
@keyframes hider {
  100% {
    opacity: 1;
  }
}
@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes b-lines {
  0% {
    width: 0;
    left: 100%;
    box-shadow: inset 0 0 #f168a1;
  }
  20% {
    width: 100%;
    left: 0%;
    box-shadow: inset 25em 0 #ff788a;
  }
  45% {
    width: 100%;
    left: 0%;
    background-image: linear-gradient(to right, #e04e8b, #f38d64);
    background-image: -o-linear-gradient(to right, #e04e8b, #f38d64);
    background-image: -moz-linear-gradient(to right, #e04e8b, #f38d64);
    background-image: -webkit-linear-gradient(to right, #e04e8b, #f38d64);
  }
  100% {
    left: 0;
    box-shadow: inset 5em 0 #f38d64, inset 25em 0 #ff788a;
  }
}
@keyframes b-lines-2 {
  0% {
    width: 0;
    left: 100%;
    box-shadow: inset 0 0 #046a84;
  }
  20% {
    width: 100%;
    left: 0%;
    box-shadow: inset 25em 0 #046a84;
  }
  45% {
    width: 100%;
    left: 0%;
    background-image: linear-gradient(to right, #ff689d, #f54441);
    background-image: -o-linear-gradient(to right, #ff689d, #f54441);
    background-image: -moz-linear-gradient(to right, #ff689d, #f54441);
    background-image: -webkit-linear-gradient(to right, #ff689d, #f54441);
  }
  100% {
    left: 0%;
    box-shadow: inset 5em 0 #fc5855, inset 25em 0 #046a84;
  }
}
@keyframes n-lines {
  0% {
    width: 0;
    box-shadow: inset 0 0 #ecc364;
  }
  50% {
    width: 100%;
    box-shadow: inset 0em 0 #005472;
  }
  100% {
    width: 0;
    box-shadow: inset 0em 0 #d0e888;
  }
}
@keyframes n--lines {
  0% {
    width: 0;
    box-shadow: inset 0 0 #d0e888;
  }
  50% {
    width: 50%;
    box-shadow: inset 20em 0 #ecc364;
  }
  100% {
    width: 50%;
    box-shadow: inset 0em 0 #005472;
  }
}
@keyframes s-lines {
  0% {
    height: 0;
    top: 100%;
    box-shadow: inset 25em 0 #6dc0ac;
  }
  50% {
    height: 0;
    top: 100%;
    box-shadow: inset 10 0em #e36c6c;
  }
  100% {
    background-color: #e36c7e;
    background-image: linear-gradient(60deg, #e36c7e, #f59662);
    background-image: -moz-linear-gradient(60deg, #e36c7e, #f59662);
    background-image: -webkit-linear-gradient(60deg, #e36c7e, #f59662);
    background-image: -o-linear-gradient(60deg, #e36c7e, #f59662);
    height: 100%;
    top: 0;
  }
}
@keyframes l-lines {
  0% {
    width: 0;
    left: 100%;
    box-shadow: inset 0 0 #efd16f;
  }
  40% {
    width: 100%;
    left: 0;
    box-shadow: inset 40em 0 #ead26a;
  }
  55% {
    width: 100%;
    left: 0;
    box-shadow: inset 35em 0 #8abfbc;
  }
  100% {
    width: 100%;
    left: 0;
    box-shadow: inset 0em 0 #8abfbc;
  }
}
@keyframes bghover {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/*      *      *      *      M E D I A    Q U E R I E S    *      *      *      */
@media screen and (max-width: 1500px) {
  .container {
    padding: calc(8px + 1.5625vw);
  }
  /*
      body {
          overflow-y: scroll;
      }
  */
  header {
    grid-template-columns: 1fr 1fr;
    width: 100%;
    max-width: 100%;
    grid-template-rows: 1fr;
    grid-template-areas: "logo mn";
    justify-content: space-around;
    gap: 0.1em;
  }
  .logo {
    max-width: 2.5rem;
    width: 2rem;
    margin-left: 10%;
  }
  .menu {
    display: none;
  }
  #outer_min {
    display: block;
    float: right;
  }
  .menu ul {
    width: 60%;
    display: flex;
    flex-direction: column;
    border: 3px solid #000;
  }
  .menu ul li,
  .menu li,
  .menu .menudown {
    font-size: 4vmax;
    font-weight: 300;
    height: 1em;
    letter-spacing: 0.06em;
    text-align: left;
    justify-content: center;
  }
  .menu ul li:not(:last-child),
  .menu li:not(:last-child),
  .menu .menudown:not(:last-child) {
    margin-right: 3px;
  }
  .menu ul li:hover::before, .menu ul li:hover::after,
  .menu li:hover::before,
  .menu li:hover::after,
  .menu .menudown:hover::before,
  .menu .menudown:hover::after {
    width: 60%;
  }
  .menu ul li ul,
  .menu ul ul,
  .menu li ul,
  .menu sub {
    min-width: 20.202020202rem;
    left: 68%;
    top: 0;
  }
  .menu ul li ul li,
  .menu ul ul li,
  .menu li ul li,
  .menu sub li {
    font-size: 2.5vw;
    display: inline-flex;
  }
  .menu ul li ul li:last-child,
  .menu ul ul li:last-child,
  .menu li ul li:last-child,
  .menu sub li:last-child {
    margin-left: 4px;
  }
  .min_nav {
    grid-area: mn;
    display: block;
    opacity: 1;
    padding-right: 3em;
    overflow: visible;
  }
  #min_navdown {
    width: 92%;
    height: auto;
    z-index: 99;
  }
  #min_navdown a:hover {
    font-weight: 200;
  }
  #min_navdown ul {
    padding: 0.1rem;
  }
  #min_navdown ul li,
  #min_navdown li,
  #min_navdown .min_menudown,
  #min_navdown .min_a,
  #min_navdown .min_b {
    font-size: 2vmax;
    padding: 0.02em;
    color: #141b22;
    height: 100%;
    width: 100%;
  }
  #min_navdown ul li:not(.btn)::after,
  #min_navdown li:not(.btn)::after,
  #min_navdown .min_menudown:not(.btn)::after,
  #min_navdown .min_a:not(.btn)::after,
  #min_navdown .min_b:not(.btn)::after {
    display: block;
    content: "";
    position: absolute;
    width: 100%;
    height: 1.5px;
    left: 0;
    bottom: 0;
    background-color: #eeb03b;
  }
  #min_navdown ul li:hover,
  #min_navdown li:hover,
  #min_navdown .min_menudown:hover,
  #min_navdown .min_a:hover,
  #min_navdown .min_b:hover {
    color: #e8984c;
    font-weight: 200;
  }
  #min_navdown ul li:hover:not(.btn)::after,
  #min_navdown li:hover:not(.btn)::after,
  #min_navdown .min_menudown:hover:not(.btn)::after,
  #min_navdown .min_a:hover:not(.btn)::after,
  #min_navdown .min_b:hover:not(.btn)::after {
    display: block;
    content: "";
    position: absolute;
    width: 100%;
    height: 1.5px;
    left: 0;
    bottom: 0;
    background-color: #bed5c9;
  }
  #min_navdown ul li ul,
  #min_navdown ul ul,
  #min_navdown li ul,
  #min_navdown sub {
    display: inline-block;
    padding: 0.1em;
    height: auto;
  }
  #min_navdown ul li ul li,
  #min_navdown ul ul li,
  #min_navdown li ul li,
  #min_navdown sub li {
    height: 3em;
    letter-spacing: 0.1rem;
    margin: 0.2rem auto;
  }
  ._fill {
    margin: auto;
  }
  .inner_slides {
    padding: 0.1rem;
  }
  .main_qt {
    padding: calc(8px + 1.5625vw);
    align-items: center;
    justify-content: center;
    margin: auto;
  }
  .b_box {
    margin-right: 1%;
  }
  .inner {
    font-size: 2vmin;
    padding: 1em;
  }
}
@media screen and (max-width: 1500px) and (max-height: 800px) {
  /*

  .slide_img {
      width: 75%;
      max-width: 85%;
  }

  .slide_dots {
      display: flex;
      flex-direction: column;
      width: 30px;
      top: 0;
      left: 0px;
      padding: 0;
      height: 150px;
      //border: 5px solid #da8afc;
      //transform: rotate(90deg);
  }

  .slide_dot:not(:last-child) {
      margin-right: 0
  }
      */
}
@media screen and (max-width: 1024px) {
  .container {
    grid-template-rows: 20% auto 7%;
    grid-template-columns: 1fr;
    grid-template-areas: "hd" "mc" "ft";
    gap: 0.1rem;
  }
  .logo {
    width: 6em;
  }
  #min_btn {
    width: 3.5em;
    max-width: 3.5em;
    height: 3.5em;
  }
  .main_qt {
    margin-left: 12%;
  }
  ._fill {
    margin: auto;
  }
  .bk_inner {
    font-size: 1.85vmin;
  }
  /*
      .inner_slides {
          border: 3px solid #afd516;
      }
  */
  /*
  .buttons,
  .buttons:link,
  .buttons:active {

      &:nth-child(2) {
          width: 20rem;
          max-width: 20rem;
      }
  }
      */
}
@media screen and (max-width: 1024px) and (min-height: 790px) {
  .container {
    margin: auto;
  }
  .slide_img {
    float: none;
    width: 60%;
    max-width: 50%;
    padding: 0;
    margin: auto;
  }
  .main_qt {
    margin: 0;
  }
}
@media screen and (max-width: 800px) {
  #b_rev_,
  #b_rev {
    display: block;
  }
  #b_rev_ ._bx,
  #b_rev ._bx {
    display: flex;
    flex-flow: column wrap;
  }
}
@media screen and (max-width: 800px) and (max-height: 789px) {
  #b_rev_,
  ._bx {
    display: none;
  }
}
@media screen and (max-width: 600px) {
  body {
    overflow-y: auto;
  }
  .outer_slides {
    width: 100%;
    max-width: 100%;
    height: 100%;
  }
  .bk_inner {
    font-size: 2.7vmin;
  }
  h2 {
    margin-right: 0.1em;
    padding: 0.07em;
    min-width: 100%;
    font-size: 5.7vmax;
  }
  .slide_dots {
    width: 20px;
    top: 0;
    left: 8px;
    padding: 0;
    height: 150px;
  }
  .slide_dot {
    width: 7px;
    height: 10px;
    float: left;
    margin-bottom: 1rem;
    border-radius: 1px;
  }
  #inner_slides {
    grid-template-columns: 1fr;
    grid-template-areas: "im" "txt";
  }
  .slide_img {
    width: 100%;
  }
}
@media screen and (max-width: 600px) and (max-height: 2000px) {
  .main_qt {
    margin: auto;
  }
}
@media screen and (max-width: 510px) {
  h2 {
    font-size: 10vmin;
  }
  .inner {
    font-size: 3.5vmin;
    line-height: 0.8em;
    margin-left: 2.5%;
  }
  .content_main {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    grid-template-areas: "img" "txt";
    gap: 0.05em;
    padding: 1.5em;
    align-items: center;
    justify-content: center;
    margin: auto;
    clear: both;
  }
  .inner_img {
    display: none;
  }
  .inner_bks {
    margin: auto;
  }
  ._i {
    font-size: 2.9vmin;
  }
  /*
  #min_navdown {

      ul li ul,
      ul ul,
      li ul,
      sub {
          li {
              font-size: clamp(14px, 7vw, 22px);
          }
      }
  }

  */
}
@media screen and (max-width: 450px) {
  .main_qt {
    margin: auto;
  }
}